/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
if (document.currentScript === undefined) {
  document.currentScript = {};
}

// polyfills
(function (e) {
  e.matches || (e.matches = e.matchesSelector || function (selector) {
    var matches = document.querySelectorAll(selector), th = this;
    return Array.prototype.some.call(matches, function (e) {
      return e === th;
    });
  });
})(Element.prototype);

(function (e) {
  e.closest = e.closest || function (css) {
    var node = this;
    while (node) {
      if (node.matches(css)) {
        return node;
      } else {
        node = node.parentElement;
      }
    }
    return null;
  }
})(Element.prototype);

(function(doc, proto) {
  try { // check if browser supports :scope natively
    doc.querySelector(':scope body');
  } catch (err) { // polyfill native methods if it doesn't
    ['querySelector', 'querySelectorAll'].forEach(function(method) {
      var nativ = proto[method];
      proto[method] = function(selectors) {
        if (/(^|,)\s*:scope/.test(selectors)) { // only if selectors contains :scope
          var id = this.id; // remember current element id
          this.id = 'ID_' + Date.now(); // assign new unique id
          selectors = selectors.replace(/((^|,)\s*):scope/g, '$1#' + this.id); // replace :scope with #ID
          var result = doc[method](selectors);
          this.id = id; // restore previous id
          return result;
        } else {
          return nativ.call(this, selectors); // use native code for other selectors
        }
      }
    });
  }
})(window.document, Element.prototype);

window.googleLoaded = function() { };
